@import 'assets/scss/variables';
@import 'assets/scss/mixins';
@import 'assets/scss/icn';

@import '~bootstrap/scss/bootstrap.scss';

@include font-face(Roboto, Roboto-Black, 900, normal);
@include font-face(Roboto, Roboto-Bold, bold, normal);
@include font-face(Roboto, Roboto-Medium, 500, normal);
@include font-face(Roboto, Roboto-Regular, 400, normal);
@include font-face(Roboto, Roboto-Light, 300, normal);
@include font-face(Roboto, Roboto-Thin, 100, normal);

:root {
  --primary-color: #fb5700;
}
/*--- Common Css ---*/
*,
*:after,
*:before {
  @include prefix(box-sizing, border-box);

  vertical-align: top;
}

html {
  @include prefix(text-size-adjust, none);
}

body,
html {
  // background: $white url('assets/images/icn-shape.svg') no-repeat scroll top center;
  background-size: contain;
  color: $body-color;
  font-family: $font;
  font-size: $body-font-size;
  line-height: $body-line-height;
  font-weight: $font-weight-normal;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  min-height: 100vh;
}

figure {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0px;
  overflow: hidden;
}

img {
  @extend .transition;

  max-width: 100%;
  max-height: 100%;
  outline: none;
  border: none;
  height: auto;
  width: auto;
}

svg {
  @extend .transition;

  max-width: 100%;
  height: auto;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

.absolute-img {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}

.picture > img {
  position: absolute;
  top: 0px;
  left: 0px;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.object-fit {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.object-fit img {
  opacity: 0;
}

input,
textarea,
button,
select,
option {
  @include prefix(appearance, none);
  outline: none;
}

select {
  background-repeat: no-repeat;
  background-position: right 17px center;
  padding-right: 35px !important;
}

option {
  font-weight: normal;
  line-height: 18px;
  padding: 0px 15px;
}

ul,
ul li,
ol,
ol li {
  list-style: none;
  padding: 0px;
  margin: 0px;
  position: relative;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $font;
  font-weight: $font-weight-bold;
  padding: 0px;
  margin: 0px;
  width: 100%;
}

h1,
.h1 {
  font-size: $h1-font-size;
  line-height: $h1-line-height;
}
h2,
.h2 {
  font-size: $h2-font-size;
  line-height: $h2-line-height;
}
h3,
.h3 {
  font-size: $h3-font-size;
  line-height: $h3-line-height;
}
h4,
.h4 {
  font-size: $h4-font-size;
  line-height: $h4-line-height;
}
h5,
.h5 {
  font-size: $h5-font-size;
  line-height: $h5-line-height;
}
h6,
.h6 {
  font-size: $h6-font-size;
  line-height: $h6-line-height;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  outline: none;
}

a,
.a {
  @extend .transition;
  color: $blue;
  display: inline-block;
  cursor: pointer;
}

a:hover {
  color: var(--primary-color);
}

:hover,
:visited,
:active,
:focus {
  outline: none !important;
}

:last-child:not(img),
:only-child:not(img) {
  margin-bottom: 0px !important;
}

p {
  font-size: $p-font-size;
  color: $body-color;
  margin: 0 0 20px;
}

p:empty {
  display: none;
}

label {
  margin-bottom: 0px;
}

small,
.small {
  font-size: $small-font-size;
  line-height: $small-line-height;
  display: inline-block;
}

section {
  display: inline-block;
  width: 100%;
  position: relative;
}

sup {
  font-size: 16px;
  top: 0px;
  display: inline-block;
  line-height: initial;
}

::-webkit-input-placeholder {
  color: $secondary;
  opacity: 0.5;
}
::-moz-placeholder {
  color: $secondary;
  opacity: 0.5;
}
:-ms-input-placeholder {
  color: $secondary;
  opacity: 0.5;
}
:-moz-placeholder {
  color: $secondary;
  opacity: 0.5;
}

::-moz-selection {
  color: $white;
  background: var(--primary-color);
}
::selection {
  color: $white;
  background: var(--primary-color);
}

.wave {
  position: absolute;
  width: 100%;
}

.transition,
::before,
::after {
  @include prefix(transition, all 0.4s ease-in-out 0s);
}

.rounded-circle {
  @include prefix(border-radius, 50%);
}

.text-container {
  padding: 16px 40px;
}

.text {
  font-size: 16px;
  line-height: 1.56;
  font-weight: 400;
}

.text-blue {
  color: $dark-blue;
}

.z-20 {
  z-index: 20;
}

/*--- Input ---*/
.form-group {
  position: relative;
  margin-bottom: 15px;

  &.is-invalid {
    background-color: #fff4f4;
    @include prefix(border-radius, 4px);
    background-image: url('assets/images/icn-error.svg');
    background-position: right 15px top 9px;
    background-size: initial;
    background-repeat: no-repeat;

    .invalid-feedback {
      display: block;
      position: absolute;
      bottom: -15px;
      left: 0px;
    }
  }

  .icn-eye {
    position: absolute;
    right: 8px;
    top: 22px;
    margin: auto;
    cursor: pointer;
    z-index: 1;
  }
}

input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type='password'] {
  padding-right: 30px !important;
}

.form-control {
  padding: 10px 15px;
  font-size: 16px;
  line-height: 38px;
  border: 1px solid transparent;

  @extend .transition;
  @include prefix(border-radius, 10px);

  -webkit-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  -moz-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  -ms-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  -o-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);

  &[disabled] {
    background-color: #fff;
  }

  &:focus {
    color: $body-color;
    background-color: $white;
    border-color: var(--primary-color);
    -webkit-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
    -moz-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
    -ms-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
    -o-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
    box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  }

  &.is-invalid {
    border-color: $red;
    background-color: #fff4f4;
    background-image: url('assets/images/icn-error.svg');
    background-position: right 15px center;
    background-size: initial;
    padding-right: 30px;

    &:not(.form-normal-password)[type='password'],
    &:not(.form-normal-password)[name='password'] {
      background-position: right 40px top 18px !important;
      padding-right: 60px !important;
    }
  }
}

.form-label {
  margin-bottom: 10px;
  @extend .transition;

  sup {
    vertical-align: top;
  }
}

.button {
  display: inline-block;
  border: none;
  color: $white;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  line-height: 48px;
  text-align: center;
  align-self: center;
  max-width: 380px;
  width: 100%;
  @extend .transition;

  @include prefix(box-shadow, 2px 6px 20px 2px rgba(251, 87, 0, 0.42));
  @include prefix(border-radius, 24px);

  background: -webkit-linear-gradient(
    275deg,
    #fb5700 -2.7%,
    #fc510a 53.72%,
    #ff007a 127.81%,
    #fc510a 201%,
    #fb5700 -250%
  );
  background: -moz-linear-gradient(
    275deg,
    #fb5700 -2.7%,
    #fc510a 53.72%,
    #ff007a 127.81%,
    #fc510a 201%,
    #fb5700 -250%
  );
  background: -ms-linear-gradient(
    275deg,
    #fb5700 -2.7%,
    #fc510a 53.72%,
    #ff007a 127.81%,
    #fc510a 201%,
    #fb5700 -250%
  );
  background: -o-linear-gradient(
    275deg,
    #fb5700 -2.7%,
    #fc510a 53.72%,
    #ff007a 127.81%,
    #fc510a 201%,
    #fb5700 -250%
  );
  background: linear-gradient(
    275deg,
    #fb5700 -2.7%,
    #fc510a 53.72%,
    #ff007a 127.81%,
    #fc510a 201%,
    #fb5700 -250%
  );

  &:hover {
    color: $white;
  }
}

.back-button {
  margin-bottom: 16;
  display: flex;
  align-items: baseline;
  justify-content: center;

  & svg {
    fill: #fff;
    margin-right: 10px;
  }
}

.invalid-feedback {
  padding-left: 8px;
  margin-top: 14px;
  font-size: 12px;
  line-height: 11px;
  color: $red;
  font-weight: normal;
}

.form-check {
  min-height: initial;
  padding-left: 20px;
  display: inline-block;
  position: relative;
  line-height: 18px;
  width: 100%;
  vertical-align: middle;

  .form-check-input {
    width: 10px;
    height: 10px;
    margin: 0px auto;
    border: 1px solid #828282;
    position: absolute;
    top: 4px;
    left: 0px;

    &[type='checkbox'] {
      width: 16px;
      height: 16px;
      top: 0px;

      @include prefix(border-radius, 0);

      &:before {
        @include prefix(border-radius, 0);
        width: 8px;
        height: 8px;
      }
    }

    &:focus {
      @include prefix(box-shadow, none);
    }

    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      margin: auto;
      width: 6px;
      height: 6px;
      background-color: $blue;
      opacity: 0;
      visibility: hidden;

      @include prefix(border-radius, 50%);
    }

    &:checked {
      background: transparent none no-repeat scroll center center;
      border-color: $blue;

      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .form-check-label {
    font-size: 12px;
    line-height: 18px;
    display: inline-block;
    cursor: pointer;
  }
}

.form-check-input {
  &.is-invalid {
    ~ .form-check-label {
      color: $body-color;
    }
  }
}

.react-time-picker__wrapper {
  padding: 0px 15px;
  font-size: 16px;
  line-height: 58px;
  border: 1px solid transparent !important;
  @include prefix(border-radius, 10px !important);
  -webkit--ms-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8),
    0px 4px 12px rgba(148, 155, 165, 0.5) !important;
  -moz--ms-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5) !important;
  -ms-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5) !important;
  -o-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5) !important;
  box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5) !important;
  min-width: 90px;
  max-width: 90px;
}

.react-time-picker {
  span {
    color: #000;
  }
}

.netrnow-select__control {
  padding: 0px;
  font-size: 16px;
  -webkit-box-flex-wrap: nowrap !important;
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;

  flex-wrap: wrap;
  line-height: 38px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid transparent !important;
  @include prefix(border-radius, 10px !important);
  -webkit--ms-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8),
    0px 4px 12px rgba(148, 155, 165, 0.5) !important;
  -moz--ms-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5) !important;
  -ms-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5) !important;
  -o-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5) !important;
  box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5) !important;

  .netrnow-select__value-container {
    padding: 0px 15px;

    & > div {
      padding: 0px !important;
      margin: 0px !important;
    }
  }

  .netrnow-select__indicator-separator {
    background-color: #828282;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .css-b8ldur-Input {
    margin: 0px !important;
    padding: 0px !important;
  }

  .netrnow-select__indicator {
    padding: 0px;
    width: 35px;
    height: 35px;
    position: relative;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid #828282;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      margin: auto;
    }

    svg {
      display: none;
    }
  }

  .netrnow-select__placeholder {
    margin: 0px !important;
  }
}

.css-26l3qy-menu {
  ul {
    li {
      label {
        padding: 0px !important;
        font-size: $body-font-size !important;
        line-height: $body-line-height !important;
      }
    }
  }

  @include prefix(border-radius, 10px !important);
  -webkit--ms-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8),
    0px 4px 12px rgba(148, 155, 165, 0.5) !important;
  -moz--ms-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5) !important;
  -ms-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5) !important;
  -o-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5) !important;
  box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5) !important;
}

.material-form {
  .form-group {
    &:not(.form-group-radio) {
      .form-control {
        border-bottom-color: #e5e5e5;
        padding: 25px 8px 5px;
        line-height: 28px;
        background-position: right 15px center;

        @include prefix(border-radius, 4px 4px 0 0);
        @include prefix(box-shadow, none);

        &:focus {
          border-color: transparent transparent var(--primary-color) transparent;
          @include prefix(box-shadow, none);
        }

        &:focus {
          & + .form-label {
            font-size: 12px;
            top: 4px;
          }
        }

        &.is-invalid {
          border-color: transparent transparent #e5e5e5 transparent;
          background-color: #fff4f4;
          background-image: url('assets/images/icn-error.svg');
          background-position: right 15px center;
          background-size: initial;
          padding-right: 30px;

          & + .form-label {
            font-size: 12px;
            top: 4px;
          }
        }
      }

      .form-label {
        position: absolute;
        top: 11px;
        left: 8px;
        margin-bottom: 0px;
      }

      &.input-focus {
        .form-label {
          font-size: 12px;
          top: 4px;
        }
      }
    }
  }

  .form-group-radio {
    margin-top: 25px;
    display: inline-block;
    width: 100%;
  }
}

@include responsive(sm) {
  .material-form {
    .form-group-radio {
      margin-top: 15px;
    }
  }
}

/*--- Header ---*/
.link-back {
  @extend .transition;
  display: inline-block;
  line-height: 20px;
  position: absolute;
  top: 53px;
  left: 40px;
  font-size: 12px;
  color: $blue;
  cursor: pointer;
}
.link-back:hover {
  color: var(--primary-color);
}

.brand-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  //   padding: 50px 0;
  // text-align: center;

  a {
    display: inline-block;

    img {
      // width: 100%;
      height: 70px;
      object-fit: contain;
    }
  }
}

.devider {
  display: inline-block;
  width: 100%;
  text-align: center;
  min-height: 2px;
  margin-top: 35px;
  margin-bottom: 35px;
  position: relative;

  &::before {
    content: '';
    height: 2px;
    width: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    left: 0px;
    background-color: #e9f2f9;
  }

  span {
    display: inline-block;
    padding: 0px 5px;
    background-color: $white;
    position: relative;
    z-index: 1;
  }
}

.mobile-menu-container {
  position: relative;
  padding: 20px 35px;
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #d6d3d3;
  z-index: 50;
  .navbar-brand {
    padding: 0 !important;
    height: unset !important;
    .logo {
      position: relative;
      max-height: 50px;
    }
  }

  .mobile-side-menu {
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    z-index: 40;
    padding: 0;

    .top-nav {
      display: flex;
      align-items: center;
      gap: 2.6rem;
      // flex-direction: column;

      // .nav-item:first-child {
      //   .nav-link {
      //     color: $primary;
      //   }
      // }

      .nav-item {
        position: relative;

        .nav-link {
          display: flex;
          align-items: center;
          gap: 7px;
          padding: 0 !important;
          font-weight: 700;
          font-size: large;
          color: $secondary;
          // text-transform: capitalize;
          cursor: pointer;

          & > .arrow-icon {
            font-size: 1.8rem;
            transform: rotate(180deg);
            line-height: 0;
            margin-top: -12px;
            font-weight: 500;
          }

          & > .arrow-up-animate {
            animation: rotate 0.2s ease-in-out forwards;
          }
        }

        & > .sub-nav {
          position: absolute;
          top: 100%;
          right: 0;
          width: 180px;
          transform: translateY(3%);
          display: flex;
          flex-direction: column;
          background-color: white;
          box-shadow: 0 0 5px 1px silver;
          border-radius: 5px;

          p {
            color: $secondary;
            border-bottom: 1px solid #e0e0e0;
            margin: 0;
            padding: 12px 1rem;
            cursor: pointer;
            font-weight: 600;
          }
        }
        .signIn-btn {
          font-weight: 500;
        }

        .signIn-text {
          display: none;
        }
      }
    }

    .bottom-nav {
      display: none;
      flex-direction: column;
      a {
        font-weight: 600;
        font-size: 16px;
        color: $body-color;
        border-top: 1px solid rgb(226, 226, 226);
      }
    }
  }

  .mobile-menu-icon {
    position: relative;
    display: none;
    flex-direction: column;
    gap: 4px;
  }

  .menu-bar {
    height: 2.5px;
    width: 22px;
    border-radius: 1rem;
    background-color: #0f0e0e;
    // box-shadow: 0 0 0.5px 0.5px gray;
    // transition: all .2s ease-in-out;
    flex-shrink: 0;
  }

  .close-menu {
    transform: rotate(45deg) translate(4.95px, 4.5px);
  }

  .close-menu-reverse {
    transform: rotate(-45deg) translate(4.9px, -4.5px);
  }
}

.mobile-menu-container.hidden {
  display: none;
  padding: 20px 15px;
  border-bottom: 2px solid #fb5700;
}

.mobile-menu-fixed {
  position: fixed;
  top: 0;
  left: 0;
  height: 57.5px;
  width: 100%;
}

@keyframes rotate {
  0% {
    transform: rotate(180deg);
  }
  100% {
    margin-top: 12px;
    transform: rotate(0deg);
  }
}

@include responsive(md) {
  .mobile-menu-container.hidden {
    display: flex;
  }
  .mobile-menu-container {
    padding: 20px 15px;
    border-bottom: 2px solid #e4e4e4;
    .navbar-brand {
      .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .mobile-menu-icon {
      display: flex;
    }

    .mobile-side-menu {
      position: fixed;
      top: 57.5px;
      left: 0;
      width: 100%;
      height: calc(100vh - 57.5px);
      justify-content: space-between;
      flex-direction: column;
      overflow-y: scroll;

      .top-nav {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;

        .nav-item {
          width: 100%;
          .nav-link {
            border-bottom: 1px solid rgb(226, 226, 226);
            width: 100%;
            padding: 1rem 1.5rem !important;
          }

          & > .sub-nav {
            position: relative;
            width: 100%;
            box-shadow: none;
            top: 0;
            width: 100%;
            transform: translateY(0);
            display: flex;
            flex-direction: column;

            p {
              padding: 12px 2rem;
            }
          }

          .signIn-btn {
            display: none;
          }

          .signIn-text {
            display: block;
          }
        }
      }

      .bottom-nav {
        display: flex;
      }
    }

    .close-sidebar {
      transform: translateX(-100%);
    }

    .desktop-menu {
      display: none;
    }
  }
}

/*--- Box ---*/
.main-section {
  padding: 60px 15px;
}

.box {
  display: flex;
  flex-direction: column;
  max-width: 460px;
  /*height: 650px;*/
  width: 100%;
  min-height: calc(100vh - 120px);
  background: $white;
  position: relative;

  @include prefix(border-radius, 16px);

  -webkit-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  -moz-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  -ms-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  -o-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);

  sup {
    color: $dark-primary;
    margin: 0px 5px;
  }
}

.box-head {
  padding: 40px;
  text-align: center;

  h1 {
    margin-bottom: 4px;
    position: relative;
    display: inline-block;
    padding: 8px 55px;
    width: auto;

    i {
      position: absolute;
      left: 0px;
      top: 0px;
      bottom: 0px;
      margin: auto;
    }
  }

  h2 {
    font-weight: normal;
  }

  span {
    font-size: 10px;
    line-height: 12px;
    margin-top: 6px;
    display: inline-block;
  }
}

.box-data {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  -ms-flex: 1;
  flex: 1;
}

.box-footer {
  padding-top: 40px;
}

.access-notes {
  border: 1px solid grey;
}

.main-title {
  padding: 51px 70px;
  text-align: center;
  display: inline-block;
  width: 100%;
  color: $dark-blue;

  h1 {
    color: $dark-blue;
  }

  span {
    font-size: 10px;
    line-height: 12px;
    margin-top: 6px;
    display: inline-block;
  }
}

/*--- Home Page Section ---*/

#loom-companion-mv3 {
  display: none;
}

.google-maps-container{
  width: 100%;
  height: 80%;
}

.map-box {
  display: flex;
  border-radius: 6px;
  padding: 3px;

  .switch-tab {
    cursor: pointer;
    padding: 4px 25px;
    background-color: white;
    border-radius: 6px;
    color: silver;
  }
}

.home-page-screen {
  padding: 20px 0 0;
  .container {
    max-width: 1320px;
    width: 100%;
    // border: 1px solid gray;
    padding: 0 20px;

    .banner {
      height: 500px;
      width: 100%;
      border-radius: 2rem;
      overflow: hidden;
      padding: 20px 4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-image: url('./assets//images//home-banner-Image-min.png');
      background-size: cover;
      background-position: 25% 50%;
      background-color: #ecf1f7;

      & > h1 {
        font-size: 45px;
        color: $secondary;
        line-height: normal;
      }

      & > p {
        font-size: 20px;
        width: 30%;
        color: $body-color;
        line-height: 1.8rem;
        margin-top: 2rem;
      }
    }

    .cards-container {
      padding: 5rem 0;

      & > .heading {
        font-size: 35px;
        color: $secondary;
      }

      & > .detail {
        color: $body-color;
        font-size: 20px;
      }

      .cards-box {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 1.2rem;
        margin: 4rem 0;

        .card-body {
          border: 1px solid silver;
          border-radius: 1rem;
          padding: 1.5rem;

          .card-heading {
            button {
              flex-shrink: 0;
              width: 45px;
              height: 45px;
              border-radius: 0.7rem;
              font-weight: 400;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .heading {
              color: $secondary;
              font-size: 19px;
            }
          }

          .description {
            margin-top: 1rem;
          }
        }
      }

      & > button {
        font-weight: 500;
        word-spacing: 0.2rem;
        max-width: 300px;
        width: 100%;
      }
    }
  }

  .footer {
    p {
      font-weight: 500;
      color: $body-color;
    }

    hr {
      height: 16px;
      width: 1px;
      background-color: $body-color;
      opacity: 1;
    }

    img {
      width: 20px;
    }
  }
}

@include responsive(xl) {
  .home-page-screen {
    .container {
      .banner {
        height: 400px;

        & > h1 {
          font-size: 35px;
        }

        & > p {
          font-size: 17px;
          width: 30%;
          line-height: 1.5rem;
        }
      }
    }
  }
}

@include responsive(lg) {
  .home-page-screen {
    .container {
      .banner {
        height: 350px;

        & > h1 {
          font-size: 32px;
        }

        & > p {
          font-size: 15px;
          width: 35%;
          line-height: 1.3rem;
          margin-top: 0.6rem;
        }
      }

      .cards-container {
        padding: 4rem 0;

        & > .heading {
          font-size: 30px;
        }

        & > .detail {
          font-size: 18px;
        }

        .cards-box {
          gap: 1.4rem;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          max-width: 600px;
          width: 100%;
          margin: 4rem auto;

          .card-body {
            padding: 1rem;

            .card-heading {
              .button {
                height: 40px;
                width: 40px;
              }

              h1 {
                font-size: 18px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}

@include responsive(md) {
  .home-page-screen {
    .container {
      .banner {
        padding: 20px 1.6rem;
        height: 230px;

        & > h1 {
          font-size: 22px;
        }

        & > p {
          font-size: 11px;
          width: 50%;
          line-height: 1rem;
          margin-top: 0.6rem;
        }
      }

      .cards-container {
        & > .heading {
          font-size: 24px;
        }

        & > .detail {
          font-size: 14px;
        }

        .cards-box {
          grid-template-columns: repeat(1, minmax(0, 1fr));
          margin: 2rem 0;
          max-width: 100%;

          .card-body {
            .card-heading {
              button {
                width: 40px;
                height: 40px;
                font-size: 16px;
              }

              .heading {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}

/*--- Signup Section ---*/
.signup-screen {
  small {
    margin-bottom: 27px;

    @include responsive(sm) {
      margin-bottom: 17px;
    }
  }
}

/*--- Work Section ---*/
.work-item {
  margin-top: 50px;
  margin-bottom: 50px;

  li {
    margin-bottom: 40px;

    figure {
      width: 99px;
      max-width: 99px;
      flex: 0 0 auto;
      margin-right: 25px;
    }
  }

  @include responsive(sm) {
    margin-top: 0px;
    margin-bottom: 50px;

    li {
      margin-bottom: 30px;

      figure {
        width: 60px;
        max-width: 60px;
        margin-right: 20px;
      }
    }
  }
}

.varify-item {
  margin-top: 40px;
  margin-bottom: 0px;

  li {
    margin-bottom: 80px;

    &.varify-text {
      font-size: 14px;
      line-height: 20px;
      color: $body-color;

      figure {
        margin-bottom: 0px;
      }

      p {
        font-size: 14px;
        line-height: 20px;
        color: $body-color;
      }

      li {
        margin-bottom: 0px;
        padding-left: 15px;
        position: relative;

        &:before {
          content: '';
          display: inline-block;
          width: 4px;
          height: 4px;
          position: absolute;
          top: 8px;
          left: 0px;
          background-color: $body-color;
          @include prefix(border-radius, 4px);
        }
      }
    }
  }

  @include responsive(sm) {
    margin-top: 0px;
    margin-bottom: 20px;

    li {
      margin-bottom: 30px;

      figure {
        width: 60px;
        max-width: 60px;
        margin-right: 20px;
      }
    }
  }
}

/*--- OTP Screen ---*/
.input-box {
  .form-control {
    padding: 10px 0;
    min-width: 60px;
    width: 60px;
    text-align: center;
  }
}

.resend-link {
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  margin-top: 27px;
}

.no-email-error {
  display: inline-block;
  color: #f00;
  font-size: 14px;
  line-height: 18px;
  margin-top: 27px;
}

.temp-password-success {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  margin-top: 27px;
}

@include responsive(sm) {
  .input-box {
    .form-control {
      min-width: 35px;
      line-height: 28px;
      width: 35px !important;
    }
  }

  .resend-link,
  .no-email-error,
  .temp-password-success {
    margin-top: 17px;
  }
}

/*--- Customer Survey Screen ---*/

.customer-survey-screen {
  position: relative;

  .box-data {
    & > h2 {
      color: $dark-blue;
      font-size: 20px;
    }
  }

  .survey-questions {
    .survey-question-box {
      animation: loading 0.1s ease-in;
      .survey-question {
        color: $dark-blue;
      }
      .options-box {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 5px;
        margin: 1rem 0 0;
        .option-block {
          background-color: $primary;
          color: white;
          margin: 0;
          padding: 6px 12px;
          border-radius: 6px;
          font-weight: 500;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }

  .options-box-loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(256, 256, 256, 0.8);
  }
}

/*--- Upcoming Tours Screen ---*/
.previous-tours-screen,
.upcoming-tours-screen {
  .main-title {
    padding: 10px 30px;
    h1 {
      font-size: 24px;
    }
  }
  .box-data {
    .tour-cards-box {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }
}

/*--- Previous Tours Screen ---*/

.previous-tours-screen {
  //
}

/*--- Custom Tabs ---*/

.custom-tabs {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $body-color;
  .tab {
    border: 0;
    color: gray;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;
    padding: 7px 14px;
    cursor: pointer;
  }
  .tab.active {
    color: $primary;
    border: 0;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 3px;
      background-color: $primary;
      border-radius: 1rem;
      transform: translate(-50%, -1px);
      animation: stretch 0.16s ease-in forwards;
      z-index: 10;
    }
  }
}

@keyframes loading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes stretch {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

/*--- Tour Card ---*/

.tour-card {
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 0 6px 0 silver;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  img {
    width: 100%;
  }

  .tour-card-body {
    .schedule-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      border: 1px solid silver;
      border-radius: 2rem;
      padding: 9px 0;
      margin: 1rem 0;

      .date-box {
        // border-right: 1px solid silver;
      }

      .date-box,
      .time-box {
        padding: 0 14px;
      }
    }
    .room-details {
      display: flex;
      align-items: center;
      gap: 14px;
    }
  }
}

.img-text-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  img {
    width: 17px;
  }
  p {
    font-size: 13px;
  }
}

/*--- Success Screen ---*/
.success-screen {
  .box-data {
    padding-top: 40px;
  }

  figure {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-bottom: 35px;
  }

  p {
    color: $dark-blue;
  }

  @include responsive(sm) {
    .box-data {
      padding-top: 20px;
    }
  }
}

.status-title {
  padding: 15px 40px 15px 120px;
  position: relative;

  @include prefix(border-radius, 16px 16px 0 0);

  &.success {
    background-color: #e9f7ef;
    &::before {
      background: #27ae60 url('assets/images/icn-check.svg') no-repeat scroll center center;
    }
  }

  &.error {
    background-color: #fff4f4;
    &::before {
      background: url('assets/images/icn-error-large.svg') no-repeat scroll center center;
    }
  }

  &::before {
    content: '';
    display: inline-block;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0px;
    left: 40px;
    bottom: 0px;
    margin: auto;
    @include prefix(border-radius, 50%);
  }

  h1,
  span:not(.a) {
    color: $dark-blue;
  }

  span {
    margin-top: 1px;
    display: inline-block;
    margin-bottom: 4px;

    & + a {
      margin-left: 16px;
    }
  }

  a {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  @include responsive(sm) {
    padding: 10px 20px 10px 80px;

    &::before {
      left: 20px;
      width: 40px;
      height: 40px;
      background-size: 20px;
    }
  }
}

/*--- Is This You Screen ---*/
.policy-text {
  display: inline-block;
  width: 100%;
  margin-top: 25px;
  font-size: 10px;
  line-height: 16px;

  i {
    margin-right: 10px;
  }
}

/*--- Varify Screen ---*/
.note {
  display: inline-block;
  background: #f8fbfd;
  padding: 10px 20px;
  margin-top: 70px;
}

.edit-link {
  padding-left: 8px;
  i {
    margin: 5px;
  }
}

/*--- Community Screen ---*/
.community-screen {
  header {
    background-color: #f8fbfd;
    @include prefix(border-radius, 16px 16px 0 0);
  }

  .box-data {
    padding-top: 40px;
  }

  .box-footer {
    .form-group {
      margin-bottom: 40px;
    }
  }

  .community-image {
    @include prefix(border-radius, 16px);
    overflow: hidden;

    img {
      width: 100%;
      @include prefix(border-radius, 16px);
    }
  }

  @include responsive(sm) {
    .box-data {
      padding-top: 20px;
    }

    .box-footer {
      .form-group {
        margin-bottom: 20px;
      }
    }
  }
}

.required-text {
  font-size: 10px;
  line-height: 12px;
  display: inline-block;
  position: absolute;
  top: -25px;
  left: 0px;
  width: 100%;
  text-align: center;

  @include responsive(sm) {
    top: -20px;
  }
}

/*--- Search Screen ---*/
.search-screen {
  .form-control {
    background-image: url('assets/images/icn-search.svg');
    background-position: right 20px center;
    background-repeat: no-repeat;
    padding-right: 58px;
  }
}

.item-list {
  margin-top: 20px;
}

.item {
  position: relative;

  &:not(:last-child) {
    padding-bottom: 33px;
  }

  figure {
    padding-top: 31.58%;
    margin-bottom: 10px;
    @include prefix(border-radius, 16px 16px 0 0);
  }

  .item-data {
    cursor: pointer;
    padding-right: 30px;
    position: relative;

    .icn-back-arrow {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 20px;
      width: 8px;
      height: 14px;
      @include prefix(transform, rotate(180deg));
    }

    h2 {
      font-weight: 500;
      color: $dark-blue;
    }

    small {
      &:not(.small) {
        color: $dark-blue;
        display: inline-block;
        width: 100%;
      }
    }

    ul {
      li {
        &:not(:last-child) {
          margin-right: 17px;

          .icn {
            position: relative;
            vertical-align: middle;
            top: -1px;
          }
        }

        .icn {
          margin-right: 5px;
        }
      }
    }
  }

  &.item-hide-image {
    padding-top: 8px;
    padding-bottom: 8px;

    figure {
      display: none;
    }

    .item-data {
      cursor: pointer;

      .icn-back-arrow {
        top: 0px;
        bottom: 0px;
        margin: auto;
      }
    }
  }
}

/*--- Tour Screen ---*/
.tour-address {
  img {
    margin-top: 2.5px;
    filter: invert(40%) sepia(67%) saturate(4174%) hue-rotate(5deg) brightness(92%) contrast(100%);
    width: 18px;
  }

  p {
    color: $dark-blue;
    font-weight: 500;
    line-height: 21px;
  }
}

.tour-screen {
  h1 {
    color: $dark-blue;
    margin-bottom: 47px;
  }

  .box {
    sup {
      font-size: 16px;
      color: $dark-primary;
      top: 0px;
    }
  }

  .main-title {
    min-height: 120px;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: #f8fbfd;
    @include prefix(border-radius, 16px 16px 0 0);

    figure {
      margin-top: 9px;
    }
  }

  .form-group {
    margin-bottom: 45px;
  }

  .form-label {
    color: $dark-blue;
  }

  .form-custom {
    .form-label {
      margin-bottom: 20px;
    }
  }

  .form-check {
    padding-left: 24px;

    .form-check-input {
      border-color: $dark-blue;
      width: 16px;
      height: 16px;
      top: 2px;

      &:before {
        width: 10px;
        height: 10px;
      }
    }

    .form-check-label {
      color: $dark-blue;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .icn-instant {
    margin-top: 3px;
    margin-left: 8px;
  }

  .form-date {
    .form-control {
      padding-right: 50px;

      &.is-invalid {
        padding-right: 70px;
        background-position: right 55px center;

        + .icn {
          &:before,
          &:after {
            right: 36px;
          }
        }
      }
    }

    .icn {
      position: absolute;
      top: 0px;
      left: 0px;
      cursor: pointer;
      width: 100%;
      height: 100%;
      max-height: 60px;

      &:before,
      &:after {
        top: 21px;
        right: 21px;
        left: initial;
      }
    }
  }

  .custom-option {
    margin-top: 15px;
    padding-bottom: 20px;

    .form-control,
    .css-2b097c-container {
      width: 100%;
      max-width: 90px;
      min-width: 90px;
      text-align: center;
    }

    .form-check {
      margin-top: 0px;
    }

    .css-2b097c-container {
      margin-left: 15px;
    }

    @include responsive(sm) {
      flex-direction: column;
    }
  }

  .box-data {
    padding-top: 40px;
    // justify-content: center;
    .box-footer {
      .button {
        &:first-child {
          margin-right: 50px;
        }
      }
    }
  }

  @include responsive(sm) {
    .main-title {
      min-height: 110px;

      figure {
        &:not(:only-child) {
          margin-top: 0px;
        }
      }
    }

    h1 {
      margin-bottom: 20px;
    }

    .box-data {
      padding-top: 20px;

      .box-footer {
        .button {
          &:first-child {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.custom-dropdown {
  position: absolute;
  background: #fff;
  z-index: 11;
  -webkit-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  -moz-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  -ms-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  -o-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  @include prefix(border-radius, 10px);
  overflow: hidden;
  left: 0px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  & > span {
    color: $dark-blue;
    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: $small-font-size;
    line-height: 30px;
  }

  .date-btn {
    position: absolute;
    left: 165px;
    z-index: 111;
    bottom: 0px;
    right: 0px;
    padding: 20px;

    .a {
      border: none;
      padding: 0px;
      background: transparent;
      text-decoration: none;
      font-size: $small-font-size;
    }

    .btn-apply {
      background: $white;
      -webkit-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8),
        0px 4px 12px rgba(148, 155, 165, 0.5);
      -moz-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
      -ms-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
      -o-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
      box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
      @include prefix(border-radius, 8px);
      border: none;
      line-height: 30px;
      display: inline-block;
      padding: 0px 27px;
      font-size: $small-font-size;

      &:hover {
        color: blue;
      }
    }
  }

  @include responsive(sm) {
    & > span {
      bottom: initial;
      top: 110px;
      left: 15px;
    }

    .date-btn {
      left: 0px;
      padding: 15px;
    }
  }
}

.rdrDateRangePickerWrapper {
  position: relative;
  width: 100%;

  .rdrStaticRange {
    border: none;
    overflow: hidden;
    @include prefix(border-radius, 8px);

    &:hover,
    &:focus {
      .rdrStaticRangeLabel {
        background: #eff2f7;
      }
    }

    &.rdrStaticRangeSelected {
      font-weight: normal;
      background-color: #e9f2f9;
      color: initial !important;
    }

    .rdrStaticRangeLabel {
      padding: 0px 8px;
      line-height: 30px;
      color: $dark-blue;
    }
  }
}

.rdrDefinedRangesWrapper {
  border: none;
  width: 100%;
  max-width: 165px;
  padding: 20px 8px;

  .rdrStaticRanges {
    display: flex;
    flex-direction: column;

    .rdrStaticRange {
      padding: 0 8px;
      line-height: 30px;
      color: #1b325f;
      border: none;
      font-size: 12px;
      text-align: left;
      background: transparent;
      @include prefix(border-radius, 8px);
      @extend .transition;

      &.rdrStaticRangeSelected,
      &:hover {
        background-color: #e9f2f9;
      }
    }
  }

  @include responsive(sm) {
    padding: 20px 8px 40px;
    max-width: 100%;
  }
}

.react-calendar {
  width: 100%;
  max-width: 215px;
  -webkit-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  -moz-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  -ms-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  -o-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  padding: 20px 20px 70px;
  border: none;

  .react-calendar__tile {
    font-size: 12px;
    padding: 0px !important;
    line-height: 25px;
    background: transparent !important;
    @include prefix(border-radius, 50%);
    @extend .transition;
    color: $dark-blue !important;
    font-family: $font;

    &.react-calendar__month-view__days__day--neighboringMonth {
      color: $body-color !important;
    }

    &.react-calendar__tile--active {
      color: $white !important;
      background-color: var(--primary-color) !important;
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0px;
    font-weight: 400;
    line-height: 25px;
    color: $body-color;
    font-size: 12px;
    font-family: $font;
    text-transform: none;
  }

  @include responsive(sm) {
    max-width: 100%;
    padding: 15px 15px 60px;
  }
}

.react-calendar__navigation {
  height: 20px;
  margin-bottom: 15px;

  .react-calendar__navigation__arrow {
    width: 20px;
    height: 20px;
    margin: 0;
    background-color: transparent !important;
    background-repeat: no-repeat;
    background-position: center;
    font-size: 0px;
    @extend .transition;

    &:hover {
      opacity: 0.5;
    }
  }

  button {
    background-color: transparent !important;
    min-width: initial;
    background: none;
    font-weight: 400;
    line-height: 20px;
    font-size: 14px;
    color: #1b325f;
    font-family: $font;
  }
}

.link-item {
  cursor: pointer;
  background: $white;

  -webkit-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  -moz-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  -ms-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  -o-box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);
  box-shadow: 0px -4px 8px rgba(242, 242, 242, 0.8), 0px 4px 12px rgba(148, 155, 165, 0.5);

  padding: 17px 45px 12px 80px;
  position: relative;
  margin-bottom: 30px;

  @include prefix(border-radius, 8px);

  h2 {
    font-weight: normal;
    color: $dark-blue;
  }

  .icn-back-arrow {
    position: absolute;
    right: 17px;
    top: 0px;
    bottom: 30px;
    margin: auto;
    width: 8px;
    height: 14px;
    @include prefix(transform, rotate(180deg));
  }

  .icn {
    &:not(.icn-back-arrow) {
      position: absolute;
      top: 0px;
      left: 25px;
      bottom: 0px;
      margin: auto;
    }
  }

  @include responsive(sm) {
    margin-bottom: 10px;
  }
}

/*--- Share Location ---*/
.location-screen {
  .main-title {
    min-height: 120px;
    background: #f8fbfd;
    @include prefix(border-radius, 16px 16px 0 0);
  }

  .icn-pin {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    margin-top: -53px;
  }

  .icn-error-pin {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    margin-top: -53px;
  }

  h1 {
    margin-bottom: 38px;
  }

  p {
    max-width: 328px;
    margin-left: auto;
    margin-right: auto;
  }

  @include responsive(sm) {
    .main-title {
      min-height: 110px;
    }

    h1 {
      margin-bottom: 18px;
    }
  }
}

/*--- Tour Success ---*/
.tour-success-screen {
  .box-footer {
    p {
      margin-top: 16px;
      color: $body-color;
    }
  }

  .box-data {
    .h1 {
      color: $dark-blue;
    }

    .icn-home {
      margin-bottom: 20px;
    }

    label {
      font-size: 40px;
      line-height: 48px;
      color: $dark-blue;
      display: inline-block;
      width: 100%;
      font-weight: 900;
      margin: 16px 0 20px;
    }

    span {
      font-size: 18px;
      line-height: 21px;
      font-weight: 500;
      display: inline-block;
      width: 100%;

      a {
        color: var(--primary-color);

        i {
          vertical-align: middle;
          position: relative;
          top: -2px;
        }
      }
    }
  }
}

.ajax-loader {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 111;

  .spinner-border {
    border-color: $white $white $white transparent !important;
  }
}

.persona-widget__iframe {
  height: 100%;
}

/*--- Footer ---*/
footer {
  text-align: center;
  padding: 0px 40px 40px;

  span {
    img {
      margin-left: 15px;
      max-width: 118px;
      max-height: 28px;
    }
  }
}

.need-help-link {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.16;
  color: var(--primary-color);
}

.help-link {
  position: fixed;
  bottom: 0px;
  right: 50px;
  bottom: 50px;
}

@include responsive(lg) {
  .help-link {
    right: 20px;
    bottom: 20px;
  }
}

@include responsive(sm) {
  body,
  html,
  p {
    font-size: 14px;
    line-height: 24px;
  }
  .button {
    font-weight: 500;
    font-size: 16px;
  }
  .main-title {
    padding: 31px 40px;
  }
  .main-section {
    padding: 40px 15px;
    min-height: 100vh;
  }

  .box-head {
    padding: 20px;
  }

  .box-data {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .box-footer {
    padding-top: 20px;
  }

  .note {
    margin-top: 30px;
  }

  .link-back {
    top: 33px;
    left: 20px;
  }

  .brand-logo {
    // padding: 30px 50px;
  }
  .devider {
    margin-top: 25px;
    margin-bottom: 15px;
  }

  .margin-sm {
    margin-top: 10px;
  }

  footer {
    padding: 0px 20px 20px;

    span {
      img {
        margin-left: 5px;
        max-height: 20px;
        max-width: 85px;
      }
    }
  }
}

.lock-code {
  display: flex !important;
  align-items: center;
  justify-content: center;

  & img {
    margin-left: 10px;
    width: 30px;
    height: 30px;
  }
}

.spinner-btn {
  height: 50px;
}

.lock-instructions {
  text-align: left;
  font-weight: $font-weight-bold;
  color: $dark-blue;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  & p {
    font-size: 18px;
    font-weight: 700;
    color: #000;
  }
}

.spinner {
  width: 100px;
  height: 100px;
  border-color: var(--primary-color);
  border-right-color: transparent;
  margin-bottom: 20px;
}

.error-message-container {
  padding: 20px;
  background-color: #fff4f4;
  text-align: left;
}

.troubleshoot-points {
  // h4 {
  //   text-align: left;
  // }
  ol {
    margin-left: 20px;
    li {
      list-style: decimal;
      text-align: left;
      span {
        font-size: 13px;
        color: $secondary;
      }
    }
  }
}

.title {
  margin-top: 95px;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.09;
  text-align: center;
  color: $dark-blue;
}

.link-button {
  color: #1f57e9;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  transition: color 300ms linear;

  &:hover {
    color: var(--primary-color);
  }
}

.date-list {
  display: flex;
  padding: 10px;
  overflow-x: scroll;
}

.date-item {
  width: 90px;
  margin-right: 16px;
  flex-shrink: 0;
  border: 2px solid transparent;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px;
  cursor: pointer;
  text-align: center;

  &.active {
    border-color: var(--primary-color);
  }
}

.item-month {
  margin-bottom: 12px;
}

.item-date {
  font-size: 32px;
  margin-bottom: 12px;
}

.item-day {
  font-size: 16px;
  color: var(--primary-color);
}

.time-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-top: -8px;
}

.time-item {
  width: calc(100% / 4 - 8px);
  margin-left: 8px;
  margin-top: 8px;
}

.time-button {
  width: 100%;
  height: 50px;
  border: 1px solid #c3c9d4;
  cursor: pointer;

  background-color: transparent;
  font-size: 14px;
  line-height: 1.14;
  text-align: center;

  &:not(:disabled) {
    color: var(--primary-color);
  }

  &:disabled {
    cursor: auto;
  }

  &.active {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);

    & svg {
      fill: #fff;
    }
  }

  & svg {
    display: block;
    margin: 0 auto;
    fill: var(--primary-color);
    transition-duration: 0ms;
  }
}

.vertical-buttons {
  display: flex;
  flex-direction: column;
}
.vertical-buttons .swal2-confirm {
  margin-bottom: 10px;
}

.disabled {
  filter: grayscale(1);
  opacity: 0.5;
  pointer-events: none;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 4px;
}

.full-address {
  font-size: 14px;
}

.cancel-tour-success-main {
  width: 100%;
  margin: 0;
  height: 139px;
}

.cancel-tour-section {
  padding: 0;
  min-height: auto;
}

.cancel-tour-heading {
  margin: 0 !important;
}

/*  Pagination  */

.pagination {
  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0 auto;

    li.next,
    li.previous {
      a {
        color: $primary;
      }
    }

    li.selected {
      color: $primary;
      font-weight: bold;
    }
  }
}

/*  Loading Spinner  */

.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 99999;
}

.links-section {
  a {
    color: $secondary;
    font-weight: 500;

    i {
      width: 15px;
      height: 16px;
    }
  }
}

.sub-title {
  margin: 0;
  color: #000;
  font-size: 18px;
  font-weight: 700;
}

.custom-item {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: $white;
  background: -webkit-linear-gradient(
    275deg,
    #fb5700 -2.7%,
    #fc510a 53.72%,
    #ff007a 127.81%,
    #fc510a 201%,
    #fb5700 -250%
  );
  background: -moz-linear-gradient(
    275deg,
    #fb5700 -2.7%,
    #fc510a 53.72%,
    #ff007a 127.81%,
    #fc510a 201%,
    #fb5700 -250%
  );
  background: -ms-linear-gradient(
    275deg,
    #fb5700 -2.7%,
    #fc510a 53.72%,
    #ff007a 127.81%,
    #fc510a 201%,
    #fb5700 -250%
  );
  background: -o-linear-gradient(
    275deg,
    #fb5700 -2.7%,
    #fc510a 53.72%,
    #ff007a 127.81%,
    #fc510a 201%,
    #fb5700 -250%
  );
  background: linear-gradient(
    275deg,
    #fb5700 -2.7%,
    #fc510a 53.72%,
    #ff007a 127.81%,
    #fc510a 201%,
    #fb5700 -250%
  );
}

/*  Feedback Page  */

.react-simple-star-rating-tooltip {
  background-color: #fafafa !important;
}

.feedback-screen {
  #disliked,
  #loved {
    background-color: #f6f6f6;
    // color: #e7e7e7;
    padding: 8px 12px;
  }
}

.image-gallery-custom {
  & .image-gallery-svg {
    width: 50px;
    height: 50px;
  }
}

.skeleton {
  width: 100%;
  height: 300px; /* Adjust height based on your image gallery */
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.hidden {
  display: none;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.gm-style .gm-style-iw-c {
  max-width: 450px !important;
  width: 450px !important;
  max-height: 350px !important;
  height: 350px !important;
  padding-top: 2px !important;
  background: white !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3) !important;
}

.card-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 1em;
  color: black;
}

.price {
  display: flex;
  align-items: baseline;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding: 0 0.625rem;
  overflow: hidden;
}

.home-stats {
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  font-size: 1.125rem;
}

.home-stats dl {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
}

.home-stats dl > div {
  flex-basis: auto;
  flex-shrink: 0;
  padding: 0.125rem 0.5rem;
  border-right: 1px solid rgba(29, 29, 29, 0.1);
  line-height: 1.25;
}

.info {
  flex-grow: 1;
  margin-bottom: 0.7rem;
  padding-top: 0.8rem;
  font-size: 1rem;
  line-height: 1.425;
}

.feedback-modal {
  .modal-title {
    font-size: 30px;
  }

  .modal-content {
    padding: 12px;
    border-radius: 15px;
  }

  .list-group-item {
    border-radius: 10px;
    cursor: pointer;
  }

  .icon-container {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .home {
    background: rgb(243,244,246);
  }

  .calendar {
    background: rgb(219 234 254);
    color: #FABD3A;
  }

  .phone {
    background: rgb(229,231,235);
    color: rgb(55 65 81);
  }
}

